
var Nav = {

	init: function(){

		console.log( 'init nav' );

		Nav.actual = $('#nav');
		Nav.menu = Nav.actual.find('.menu');

		Nav.menu.on( 'mouseenter', '> li', Nav.menuItemHover );
		Nav.menu.on( 'mouseleave', '> li', Nav.menuItemLeave );

		Nav.timer = null;

		console.log(Nav.menu);

	},

	menuItemHover: function(){

		var menuItem = $(this);
		var menuItemLi = menuItem.closest('li');

		if( Nav.timer ) {
			clearTimeout(Nav.timer);
			Nav.timer = null;
		}

		Nav.menu.find('.chosen').removeClass('chosen');
		menuItemLi.addClass('chosen');

	},

	menuItemLeave: function() {

		var menuItem = $(this);
		var menuItemLi = menuItem.closest('li');

		Nav.timer = setTimeout(function() {
			menuItemLi.removeClass('chosen');
		}, 300);

	}


};

Nav.init();