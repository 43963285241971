
var Forms = {

	init: function(){

		Forms.toValidate = $('.form-validate');

		Forms.toValidate.on('submit', Forms.validate);

	},

	validate: function( event ){

		var $form = $(this);
		var $required = $form.find('[data-required]');
		var $email = $form.find('[data-email]');
		var errorFree = true;

		$form.find('.form-field-error').remove();
		$form.find('.errored').removeClass('errored');

		$required.each(function(){
			var $field = $(this);
			var value = $field.val().trim();

			if ( value == '' ) {
				$field.addClass('errored');
				$field.siblings('label').append('<span class="form-field-error">required</span>');
				errorFree = false;
			}

		});

		$email.each(function(){
			var $field = $(this);
			var value = $field.val().trim();

			if ( !$field.hasClass('errored') && value != '' && !Forms.isEmail( value ) ) {
				$field.addClass('errored');
				$field.siblings('label').append('<span class="form-field-error">invalid</span>');
				errorFree = false;
			}

		});

		if ( !errorFree ) {
			event.preventDefault();
		}
		
	},

	isEmail: function(email) {
		var regex = /^([a-zA-Z0-9_.+-])+\@(([a-zA-Z0-9-])+\.)+([a-zA-Z0-9]{2,4})+$/;
		return regex.test(email);
	}

};

Forms.init();