/* Constants & Global Vars
---------------------------------------------------------------------------------------------------- */

var ANIMATION_SPEED_VERY_FAST		= 'veryFast', 
	ANIMATION_SPEED_FAST			= 'fast', 
	ANIMATION_SPEED_MEDIUM			= 'medium', 
	ANIMATION_SPEED_SLOW			= 'slow', 
	ANIMATION_SPEED_VERY_SLOW		= 'verySlow';

var 	nAnimating = 0, 
    	animationSpeedTimings = {'veryFast':250, 'fast':500, 'medium':1000, 'slow':1500, 'verySlow':2000};


/* Document Ready Callbacks
---------------------------------------------------------------------------------------------------- */
	
(function($) {

	jQuery(document).ready(function ($) {
		
		console.log('Document ready.');


  /* Animate anchor links */

  $('body').on( 'click', 'a[href^="#"]', function( event ){

    var anchor = $(this).attr('href');
    var elem = $(anchor);
    var navHeight = $('#nav').outerHeight();

    console.log( navHeight );

    if ( elem.length ) {
      event.preventDefault();
      $('html, body').animate({ 
        scrollTop: elem.offset().top - navHeight
      }, 500);
    }

  } );



		
		$('.photoGrid').css( 'visibility', 'hidden' ).imagesLoaded(function(){

      $('.photoGrid').babylongrid({
        scheme: [
                 {
                     minWidth: 960,
                     columns: 3
                 },
                 {
                     minWidth: 400,
                     columns: 2
                 },
                 {
                     minWidth: 0,
                     columns: 1
                 }
               ]
               
      }).css( 'visibility', 'visible' ).each( function() {
        
        var $pic     = $(this),
            getItems = function() {
                var items = [];
                var i = 0;
                $pic.find('a').each(function() {
                    var $href   = $(this).attr('href'),
                        $size   = $(this).data('size').split('x'),
                        $width  = $size[0],
                        $height = $size[1];

     
                    var item = {
                        src : $href,
                        w   : $width,
                        h   : $height,
                        title : $(this).data('caption')
                    }
                    
                    $(this).data('index', i);
                    i++;
     
                    items.push(item);
                });
                return items;
            }
     
        var items = getItems();
        
        var $pswp = $('.pswp')[0];
        $pic.on('click', 'figure', function(event) {
            event.preventDefault();
             
            var $index = $(this).index(), 
                anchorEl = $(this).find('a'), 
                absoluteIndex = anchorEl.data('index');
                
            var options = {
                index: absoluteIndex, //$index,
                bgOpacity: 0.7,
                showHideOpacity: true
            }
             
            // Initialize PhotoSwipe
            var lightBox = new PhotoSwipe($pswp, PhotoSwipeUI_Default, items, options);
            lightBox.init();
        });
        
      });

    });










  	$('.inputSelect').customSelect();
  	
  	
  	
  	
  	// Waypoints (animate on appear)
  	
		$('.cssanimations.no-touch *[data-animate]').addClass('invisible').waypoint(function(e, direction) {
		
			var hasAnimated  = $(this).data('hasanimated'), 
				animateClass = $(this).data('animate'), 
				speed		 = ($(this).data('animatespeed')) ? $(this).data('animatespeed') : 'medium',
				classes	 = 'animated '+speed+' '+animateClass, 
				delay		 = ((nAnimating * 80) > 1000) ? 1000 : nAnimating * 80, 
				duration	 = animationSpeedTimings[speed];
				
			if(hasAnimated) return;
						
			nAnimating++;
			
			$(this).clearQueue()
					.delay(delay)
					.queue(function(){
						$(this).clearQueue()
								.removeClass('invisible')
								.data('hasanimated', true)
								.addClass(classes)
								.delay(duration)
								.queue(function(){
									$(this).removeClass(classes).addClass('animated');
									nAnimating--;
								});
					});
							
		}, { offset: '90%' });
    
    
    
    // Search Box Autocomplete
    
    $('#searchQuery').on('initSearch', function(){
      
      var el = $(this), 
          dataSource = $(el).data('datasource');
      
      if(dataSource.length) {

        $.getJSON( dataSource, function( data ) {
          $(el).trigger('initAutocomplete', {'streetData':data});
        });

      }
      
      
      
    // Init autocomplete
      
    }).on('initAutocomplete', function(e, data){
      
      
      if (typeof data === "undefined") return;
      if (typeof data.streetData === "undefined") return;
      
      var el = $(this), 
          form = $(el).closest('form');
      
      $(el).autocomplete('disable');
      $(el).autocomplete({
          lookup: data.streetData,
          minChars: 1,
          autoSelectFirst: true,
          triggerSelectOnValidInput: false,
          lookupFilter: function (suggestion, originalQuery, queryLowerCase) {
            return suggestion.value.toLowerCase().indexOf(queryLowerCase) === 0;
          },
          onSelect: function (suggestion) {

            var permalink = el.data('permalink') + '/' + suggestion['slug']  + '?searched=' + suggestion.value;

            if ( typeof( suggestion['street-type'] ) !== 'undefined' ) {
              permalink += '&type=' + suggestion['street-type'];
            }

            window.location.href = permalink;


          },
          formatResult: function (suggestion, currentValue) {

            var pattern = '(' + currentValue.replace(/[\-\[\]\/\{\}\(\)\*\+\?\.\\\^\$\|]/g, "\\$&") + ')',
                html = suggestion.value
                .replace(new RegExp(pattern, 'gi'), '<strong>$1<\/strong>')
                .replace(/&/g, '&amp;')
                .replace(/</g, '&lt;')
                .replace(/>/g, '&gt;')
                .replace(/"/g, '&quot;')
                .replace(/&lt;(\/?strong)&gt;/g, '<$1>');

                if ( suggestion.between ) {
                  html += ' (' + suggestion.between + ')'
                }
            
            // Adding suffix at the right
            //html += '<em>'+suggestion.primary_street+'</em>';
            
            return html;

          },
          showNoSuggestionNotice: true,
          noSuggestionNotice: 'No streets found.'
      });
      
      $(form).submit(function(e){
        e.preventDefault();

        var autoComp = $("#searchQuery").data('autocomplete');

        if ( autoComp.suggestions.length ) {
          autoComp.select(0);
        } else {
          $(el).trigger('showError', 'No matching streets found, please try again.');
        }

      });
      
      
      
    // Focus Search
      
    }).on('focus', function(){
      
      // var el        = $(this),
      //     navHeight = $('#nav').height();
      
      // $(el).closest('form').scrollTo({duration:500, targetOnCompletion:false, offset:-(100)}, function(){});
      
      
    }).on('showError', function(e, message){
      
      var el        = $(this),
          form      = $(el).closest('form'), 
          label     = $(form).find('label'), 
          navHeight = $('#nav').height();
          
        $(label).text(message).removeClass('icon-pin').addClass('error');
        $(form).cssAnimate({type:'shake', speed: ANIMATION_SPEED_VERY_FAST, delay: 0}, function(){});
      
    }).trigger('initSearch');
    

    
    // Flexslider
    
    $('.flexslider').flexslider({
                                  animation:'slide', 
                                  slideshow: false,
                                  animationLoop: false,
                                  prevText: '<span class="icons_arrow-left"></span><span class="visuallyhidden">Previous</span>',
                                  nextText: '<span class="icons_arrow-right"></span><span class="visuallyhidden">Next</span>'
                                });
    
    

  });
    
})(jQuery);


(function($) {

    $(window).load(function(event) {
    
		console.log('Window loaded.');
		
		
		// Add Fast Click
		FastClick.attach(document.body);
		

		
		$(window).bind('scroll', function(e){

		}).trigger('scroll');
		
		
		
		
		$(window).bind('orientationchange resize', function(e){

		}).trigger('resize');
		
		
		
  });
    
})(jQuery);




(function ( $ ) {
 
	$.fn.cssAnimate = function(options, callback) {

        var settings = $.extend({
            type: 'fadeIn',
            speed: 'medium', 
            delay: 0,
            invisibleOnComplete: false, 
            hiddenOnComplete: false
        }, options );
        
		var classes		 = 'animated '+settings.speed+' '+settings.type, 
			delay		 = settings.delay,
			duration	 = (Modernizr.cssanimations) ? animationSpeedTimings[settings.speed] : 0;
        
		$(this)
				.clearQueue()
				.delay(delay)
				.queue(function(){
					$(this).clearQueue()
							.addClass(classes)
							.show()
							.removeClass('invisible')
							.delay(duration*1.5)
							.queue(function(){

							    if (typeof callback == 'function') { 
							        callback.call(this); 
							    }							
							
								if(settings.invisibleOnComplete) {
									$(this).addClass('invisible');
								} 
								
								if(settings.hiddenOnComplete) {
									$(this).hide();
								}
							
								return $(this).clearQueue().removeClass(classes);
							});
				});
        
        this.css( "color", settings.color );
	    
	    return this;
	};
	
	$.fn.scrollTo = function(options, callback) {

        var settings = $.extend({
            duration: 1500, 
            offset: 0, 
            easing: 'easeInOutQuint', 
            targetOnCompletion: true
        }, options );
        
        if(!$(this).length) return this;
        
    	var toPosition = $(this).offset().top + settings.offset;
    	var el = $(this);
        $('body, html').animate({scrollTop: toPosition}, settings.duration, settings.easing, function(){ 
        
        	if(settings.targetOnCompletion) {
	        	window.location.hash = el.attr('id');
        	}

		    if (typeof callback == 'function') { 
		        callback.call(this); 
		    }
        	
        	return this; 
        });
	    
	};
 
}( jQuery ));