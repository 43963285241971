
var Subscribe = {

	init: function(){

		Subscribe.form = $('#subForm');

		Subscribe.form.on( 'submit', Subscribe.handleSubmit );

	},

	handleSubmit: function( event ) {
		event.preventDefault();

		Subscribe.form.find('.form_feedback').remove();

		$.getJSON(
		this.action + "?callback=?",
		$(this).serialize(),
		function (data) {
			if (data.Status === 400) {
				Subscribe.handleFail( data.Message );
			} else {
				Subscribe.handleSuccess( data.Message );
			}
		});

	},

	handleSuccess: function( message ) {

		var feedback = $('<div class="form_feedback" />');
		feedback.addClass('is-success');
		feedback.text( message );
		Subscribe.form.prepend( feedback );
		Subscribe.form.trigger("reset");

	},

	handleFail: function( message ){

		var feedback = $('<div class="form_feedback" />');
		feedback.addClass('is-error');
		feedback.text( message );
		Subscribe.form.prepend( feedback );

	}

};

Subscribe.init();