
var Explorer = {

	init: function(){

		Explorer.actual = $('.explorer');
		Explorer.viewport = $('.explorer_viewport');
		Explorer.canvas = $('.explorer_canvas');
		Explorer.pics = $('.explorer_pics');
		Explorer.info = $('.explorer_info');
		Explorer.infoItems = $('.explorer_info_item');
		Explorer.markers = $('.explorer_info_marker');
		Explorer.overlays = $('.explorer_overlay');
		Explorer.pannerButtons = $('.explorer_panner_button');

		Explorer.activeItem = false;

		if ( $(window).width() < 750 ) {
			Explorer.setupSlider();
		} else {
			Explorer.setupPanner();
		}
		

	},

	setupSlider: function() {

		Explorer.actual.addClass( 'explorer--slider' );

		Explorer.info.flexslider({
			selector: '.explorer_info_items > .explorer_info_item',
			animation: 'slide',
			smoothHeight: true,
			namespace: 'explorer_slider_',
			slideshow: false,
			prevText: '<span class="icons_arrow-left"></span> <span class="visuallyhidden">Previous</span>',
			nextText: '<span class="icons_arrow-right"></span> <span class="visuallyhidden">Next</span>',
			start: function( slider ) {

				var slide = $( slider.slides[ slider.currentSlide ] );
				Explorer.showOverlay( slide.data('explorer-id') );

			},
			before: function( slider ){

				var slide = $( slider.slides[ slider.animatingTo ] );
				Explorer.showOverlay( slide.data('explorer-id') );

			}
		});


	},

	setupPanner: function(){

		Explorer.actual.addClass( 'explorer--panner' );

		//Initialise panzoom

		Explorer.canvas.panzoom({
			minScale: 0,
			contain: 'invert',
			disableZoom: true
		});

		Explorer.canvas.on( 'panzoompan', Explorer.updatePanner );

		//Center canvas

		var x = Explorer.viewport.outerWidth()/2 - Explorer.canvas.outerWidth()/2;
		var y = Explorer.viewport.outerHeight()/2 - Explorer.canvas.outerHeight()/2;

		Explorer.canvas.panzoom( 'pan', x, y );


		// setup markers
		var proms = [];

		Explorer.infoItems.each(function( index ){

			var item = $(this);
			var delay = ( index + 1 ) * 500;
			var dfd = $.Deferred();

			proms.push( dfd.promise() );

			item.css({
				top: item.data('explorer-top'),
				left: item.data('explorer-left')
			});

			window.setTimeout(function(){
				item.addClass( 'is-setup' );
				dfd.resolve();
			}, delay);

		});

		$.when.apply( $, proms ).then(function(){
			Explorer.infoItems.addClass( 'is-pulsing' );
		});

		// setup actions

		Explorer.infoItems.on('mousedown touchstart', function( e ) {
  			e.stopImmediatePropagation();
		});

		Explorer.markers.on( 'click', Explorer.markerClick );

		Explorer.pannerButtons.on( 'click', Explorer.pannerButtonClick );

		$(window).on('resize', function() {
		  Explorer.canvas.panzoom('resetDimensions');
		});


	},

	showOverlay: function( explorerID ) {

		Explorer.overlays.filter('.explorer_overlay--active').removeClass('explorer_overlay--active');

		if ( typeof explorerID == 'undefined' || explorerID == false ) {
			Explorer.pics.removeClass('explorer_pics--active');
			return;
		}

		Explorer.pics.addClass('explorer_pics--active');
		Explorer.overlays.filter('[data-explorer-id="' + explorerID + '"]').addClass('explorer_overlay--active');

	},

	markerClick: function( event ) {

		var marker = $(this);
		var infoItem = marker.closest( Explorer.infoItems );

		if ( infoItem.is( Explorer.activeItem ) ) {

			Explorer.closeItem( Explorer.activeItem );
			Explorer.showOverlay(false);
			Explorer.activeItem = false;

		} else {

			if ( Explorer.activeItem ) {
				Explorer.closeItem( Explorer.activeItem );
			}

			Explorer.showOverlay( infoItem.data('explorer-id') );
			Explorer.activeItem = infoItem;

			Explorer.openItem( infoItem );

		}	

		event.stopPropagation();


	},

	pannerButtonClick: function(){

		var button = $(this);
		var x = 0;
		var y = 0;
		var panzoom = Explorer.canvas.panzoom( 'instance' );

		if ( button.hasClass('explorer_panner_button--up') ) {
			y = panzoom.dimensions.height / 10;
		} else if ( button.hasClass('explorer_panner_button--down') ) {
			y = -panzoom.dimensions.height / 10;
		} else if ( button.hasClass('explorer_panner_button--left') ) {
			x = panzoom.dimensions.width / 10;
		} else if ( button.hasClass('explorer_panner_button--right') ) {
			x = -panzoom.dimensions.width / 10;
		}

		Explorer.canvas.panzoom( 'pan', x, y, { relative: true } );

	},

	updatePanner: function( event, panzoom, x, y ) {

		Explorer.pannerButtons.removeClass('is-disabled');

		if ( x == 0 ) {
			Explorer.pannerButtons.filter('.explorer_panner_button--left').addClass('is-disabled');
		} 

		if ( panzoom.dimensions.width + x <= panzoom.container.width ) {
			Explorer.pannerButtons.filter('.explorer_panner_button--right').addClass('is-disabled');
		}

		if ( y == 0 ) {
			Explorer.pannerButtons.filter('.explorer_panner_button--up').addClass('is-disabled');
		} 

		if ( panzoom.dimensions.height + y <= panzoom.container.height ) {
			Explorer.pannerButtons.filter('.explorer_panner_button--down').addClass('is-disabled');
		}

	},

	openItem: function( item ) {

		item.addClass('is-active');
		Explorer.viewport.addClass('is-item-active');

	},

	closeItem: function( item ) {

		item.removeClass('is-active');
		Explorer.viewport.removeClass('is-item-active');

	}

};

$.fn.redraw = function(){
  $(this).each(function(){
    var redraw = this.offsetHeight;
  });
};

Explorer.init();










































